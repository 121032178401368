import { PUBLIC_SENTRY_DSN } from '$env/static/public'
import * as SentrySvelte from '@sentry/sveltekit'
import type { HandleClientError } from '@sveltejs/kit'

// Initialize Sentry only on production
if (import.meta.env.PROD) {
  SentrySvelte.init({
    dsn: PUBLIC_SENTRY_DSN,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      SentrySvelte.browserTracingIntegration(),
      SentrySvelte.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
  })
  SentrySvelte.setTag('svelteKit', 'browser')
}

export const handleError: HandleClientError = ({ error, event }) => {
  const errorId = crypto.randomUUID()
  // Only emit Sentry errors in production
  if (import.meta.env.PROD) {
    console.error(error, event)
    SentrySvelte.captureException(error, { contexts: { sveltekit: { event } } })
  }

  // Log error and event to browser console in development
  if (import.meta.env.DEV) {
    console.error(error, event)
  }

  return {
    message: 'A client error has occurred. I have spoken.',
    code: (error as App.Error)?.code ?? 'UNKNOWN',
    errorId,
  }
}
