import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43')
];

export const server_loads = [2,5];

export const dictionary = {
		"/(app)": [10,[2],[3]],
		"/(app)/admin": [11,[2,4],[3]],
		"/(app)/admin/organisation": [~12,[2,4],[3]],
		"/(app)/admin/roles": [~13,[2,4],[3]],
		"/(app)/analytics": [14,[2],[3]],
		"/auth/login": [38,[8]],
		"/auth/register": [39,[8]],
		"/auth/verify": [40,[8]],
		"/(app)/calendar": [~15,[2],[3]],
		"/(app)/calendar/[id]": [~16,[2],[3]],
		"/(app)/imageEditor": [17,[2],[3]],
		"/(app)/library": [~18,[2],[3]],
		"/(app)/onboarding": [19,[2],[3]],
		"/(app)/profile": [~20,[2],[3]],
		"/(app)/reports": [~21,[2],[3]],
		"/(app)/scheduler": [22,[2,5],[3]],
		"/(app)/scheduler/[tab]": [~23,[2,5],[3]],
		"/(app)/settings": [24,[2,6],[3,7]],
		"/(app)/settings/billing": [~25,[2,6],[3,7]],
		"/(app)/settings/billing/success": [26,[2,6],[3,7]],
		"/(app)/settings/brand-profile": [~27,[2,6],[3,7]],
		"/(app)/settings/brands": [~28,[2,6],[3,7]],
		"/(app)/settings/image-templates": [~29,[2,6],[3,7]],
		"/(app)/settings/organisation": [~30,[2,6],[3,7]],
		"/(app)/settings/prompt-settings": [~31,[2,6],[3,7]],
		"/(app)/settings/roles": [~32,[2,6],[3,7]],
		"/(app)/settings/social-accounts": [~33,[2,6],[3,7]],
		"/(app)/settings/user": [~34,[2,6],[3,7]],
		"/user/new": [41,[9]],
		"/user/settings": [~42,[9]],
		"/user/verify": [~43,[9]],
		"/(app)/workflows": [35,[2],[3]],
		"/(app)/workflows/[workflowId]": [~36,[2],[3]],
		"/(app)/workflows/[workflowId]/[taskId]": [~37,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';